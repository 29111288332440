<template>
  <div class="appointmentList-view">
    <div class="w50p flex-c-c pt30 pb30">
      <div class="phoneClass borderboxd-flex flex-column">
        <div style="height: 650px; width: 100%; overflow: hidden">
          <img src="../../../assets/images/content1.png" />
        </div>

        <div class="d-flex flex1 bgfff justify-content-between">
          <div
            style="width: 52px; height: 100%"
            class="flex-c-c flex-column"
            v-for="(item, index) in tabList"
            :key="index"
          >
            <img :src="item.iconPath" class="w40 h40" />
            <p class="fs6">{{ item.text }}</p>
          </div>
        </div>
      </div>
    </div>
    <div class="w50p">
      <p class="fs9 fwbold mb20 pl10">
        因微信小程序机制，更改内容后需提交小程序审核，发布后才可生效
      </p>
      <div
        class="bgf5f6 pt10 pb10 pr10 pl10 w100p borderbox mb20"
        v-for="(item, index) in tabList"
        :key="index"
      >
        <p class="fs9 fwbold mb10">导航模块</p>
        <div class="pl10 flex-a-c mb10">
          <div class="w100">
            <p class="">名称</p>
          </div>
          <el-input
            type="text"
            class="w400"
            maxlength="4"
            v-model="item.text"
            :disabled="index == 0"
            show-word-limit
          >
          </el-input>
        </div>

        <div class="pl10 flex-a-c mb10">
          <div class="w100">
            <p class="">图标样式</p>
          </div>
          <div class="flex-a-c">
            <p class="mr10">未选中状态</p>

            <el-upload
              accept="image/jpeg,image/png"
              class="addTabIcon"
              :action="uploadUrl"
              :show-file-list="false"
              :on-success="
                dynamicPicSuccess.bind(null, { index: index, data: item })
              "
              :before-upload="beforeUploadImageDynamicPic"
            >
              <img
                v-if="item.iconPath"
                :src="item.iconPath"
                class="avatar dynamic"
              />
              <i v-else class="el-icon-plus"></i>
            </el-upload>

            <div class="flex-a-c">
              <p class="mr10">选中状态</p>

              <el-upload
                accept="image/jpeg,image/png"
                class="addTabIcon"
                :action="uploadUrl"
                :show-file-list="false"
                :on-success="
                  dynamicPicSuccessA.bind(null, { index: index, data: item })
                "
                :before-upload="beforeUploadImageDynamicPic"
              >
                <img
                  v-if="item.selectedIconPath"
                  :src="item.selectedIconPath"
                  class="avatar dynamic"
                />
                <i v-else class="el-icon-plus"></i>
              </el-upload>
              <p>建议上传81px * 81px的图标</p>
            </div>
          </div>
        </div>

        <div class="pl10 flex-a-b-c">
          <div class="flex-a-c">
            <div class="w100">展示页面</div>
            <el-select
              v-model="item.subheading"
              style="width: 240px"
              placeholder="请选择"
              :disabled="index == 0"
              @change="select_tap($event, index)"
            >
              <el-option
                v-for="v in optionList"
                :key="v.text"
                :disabled="v.disabled"
                :label="v.text"
                :value="v.text"
              />
            </el-select>
          </div>
          <i
            v-if="index !== 0"
            class="el-icon-delete fs9 hover_pointer"
            @click="deleteTab(item)"
          ></i>
        </div>
      </div>
      <el-button size="small" type="primary" @click="addTab"
        >添加导航模板</el-button
      >
      <div class="flex-c-c">
        <el-button size="small" type="primary" @click="saveTab">保存</el-button>
      </div>
    </div>
  </div>
</template>

<script>
import { saveTabMenu, queryTabMenu } from "@/api/wechat";
import { getDataTimeSec } from "@/utils";
export default {
  name: "appointmentList",
  data() {
    return {
      uploadUrl: this.$store.state.uploadingUrl,
      ossUrl: this.$store.state.ossUrl,
      tabList: [
        {
          text: "名片",
          subheading: "名片",
          pagePath: "pages/index/main",
          iconPath:
            this.$store.state.ossUrl + "jquen_photos/tabbarIcon/card.png",
          selectedIconPath:
            this.$store.state.ossUrl +
            "jquen_photos/tabbarIcon/card_select.png",
        },
        {
          text: "媒体",
          subheading: "媒体",
          pagePath: "pages/media/index",
          iconPath:
            this.$store.state.ossUrl + "jquen_photos/tabbarIcon/video.png",
          selectedIconPath:
            this.$store.state.ossUrl +
            "jquen_photos/tabbarIcon/video_select.png",
        },
        {
          text: "商城",
          subheading: "商城",
          pagePath: "pages/Products/main",
          iconPath:
            this.$store.state.ossUrl + "jquen_photos/tabbarIcon/prod_gray.png",
          selectedIconPath:
            this.$store.state.ossUrl +
            "jquen_photos/tabbarIcon/prod_select.png",
        },
        {
          text: "动态",
          subheading: "动态",
          pagePath: "pages/Dynamic/main",
          iconPath:
            this.$store.state.ossUrl + "jquen_photos/tabbarIcon/browser.png",
          selectedIconPath:
            this.$store.state.ossUrl +
            "jquen_photos/tabbarIcon/browser_select.png",
        },
        {
          text: "官网",
          subheading: "官网",
          pagePath: "pages/WebSite/main",
          iconPath:
            this.$store.state.ossUrl + "jquen_photos/tabbarIcon/computer.png",
          selectedIconPath:
            this.$store.state.ossUrl +
            "jquen_photos/tabbarIcon/computer_select.png",
        },
      ],
      optionList: [
        {
          text: "名片",
          subheading: "名片",
          disabled: true,
          pagePath: "pages/index/main",
          iconPath:
            this.$store.state.ossUrl + "jquen_photos/tabbarIcon/card.png",
          selectedIconPath:
            this.$store.state.ossUrl +
            "jquen_photos/tabbarIcon/card_select.png",
        },
        {
          text: "媒体",
          subheading: "媒体",
          type: "video",
          disabled: true,
          pagePath: "pages/media/index",
          iconPath:
            this.$store.state.ossUrl + "jquen_photos/tabbarIcon/video.png",
          selectedIconPath:
            this.$store.state.ossUrl +
            "jquen_photos/tabbarIcon/video_select.png",
        },
        {
          text: "探索",
          subheading: "探索",
          type: "video",
          disabled: true,
          pagePath: "pages/explore/main",
          iconPath:
            this.$store.state.ossUrl + "jquen_photos/tabbarIcon/lightbulb-flash-line.png",
          selectedIconPath:
            this.$store.state.ossUrl + "jquen_photos/tabbarIcon/lightbulb-flash-fill.png",
        },
        {
          text: "商城",
          subheading: "商城",
          disabled: true,
          pagePath: "pages/Products/main",
          iconPath:
            this.$store.state.ossUrl + "jquen_photos/tabbarIcon/prod_gray.png",
          selectedIconPath:
            this.$store.state.ossUrl +
            "jquen_photos/tabbarIcon/prod_select.png",
        },
        {
          text: "动态",
          subheading: "动态",
          disabled: true,
          pagePath: "pages/Dynamic/main",
          iconPath:
            this.$store.state.ossUrl + "jquen_photos/tabbarIcon/browser.png",
          selectedIconPath:
            this.$store.state.ossUrl +
            "jquen_photos/tabbarIcon/browser_select.png",
        },
        {
          text: "官网",
          subheading: "官网",
          disabled: true,
          pagePath: "pages/WebSite/main",
          iconPath:
            this.$store.state.ossUrl + "jquen_photos/tabbarIcon/computer.png",
          selectedIconPath:
            this.$store.state.ossUrl +
            "jquen_photos/tabbarIcon/computer_select.png",
        },
        {
          text: "增值服务",
          subheading: "增值服务",
          disabled: false,
          pagePath: "pages/services/main",
          iconPath:
            this.$store.state.ossUrl + "jquen_photos/tabbarIcon/member.png",
          selectedIconPath:
            this.$store.state.ossUrl +
            "jquen_photos/tabbarIcon/member_select.png",
        },
      ],
      isUpdate: false,
    };
  },
  watch: {
    // tabList(val,old){
    //
    // }
    tabList: {
      handler(val, old) {
        console.log(val, "val");
        this.optionList.forEach((v) => {
          v.disabled = false;
          val.forEach((item, index) => {
            if (item.pagePath == v.pagePath) {
              v.disabled = true;
            }
          });
        });
      },
      deep: true,
      immediate: true,
    },
  },
  filters: {
    filtration(key, tabList) {
      tabList.forEach((item) => {
        if (item.text == key) {
          return true;
        } else {
          return false;
        }
      });
    },
  },
  mounted() {
    this.queryTabList();
  },
  methods: {
    //请求表格数据
    queryTabList() {
      this.loading = true;
      let data = {
        pageNum: this.pageNum,
      };
      queryTabMenu(data)
        .then((res) => {
          console.log(res);
          if (res.data && res.data.note) {
            this.tabList = JSON.parse(res.data.note);
            console.log(this.tabList);
          }
        })
        .catch((err) => {
          this.loading = false;
          this.tabList = this.tabList;
        });
    },
    beforeUploadImageDynamicPic(file) {
      var _this = this;
      var isLt10M = file.size / 1024 / 1024 < 10;
      if (["image/jpeg", "image/png"].indexOf(file.type) == -1) {
        _this.$message.error("请上传正确的图片格式");
        return false;
      }
      if (!isLt10M) {
        _this.$message.error("上传图片大小不能超过10MB哦!");
        return false;
      }
    },

    //动态图上传成功
    dynamicPicSuccess(obj, res, file) {
      var index = obj.index;
      this.$set(this.tabList[index], "iconPath", this.ossUrl + "/" + res.data);
    },
    select_tap(key, index) {
      let than = this;
      console.log(key);
      this.optionList.forEach((v) => {
        if (v.text == key) {
          than.tabList[index].pagePath = v.pagePath;
          than.tabList[index].iconPath = v.iconPath;
          than.tabList[index].subheading = v.subheading;
          than.tabList[index].selectedIconPath = v.selectedIconPath;
          than.tabList[index].type = v.type;
        }
      });
    },

    //动态图上传成功
    dynamicPicSuccessA(obj, res, file) {
      var index = obj.index;
      this.$set(
        this.tabList[index],
        "selectedIconPath",
        this.ossUrl + "/" + res.data
      );
    },
    deleteTab(item, index) {
      if (index == 0) {
        return false;
      }
      let than = this;
      if (than.tabList.length == 2) {
        return than.$message.error("小程序最少需要两个导航栏！");
      }
      than.tabList.forEach((v, k) => {
        if (v.text == item.text) {
          than.tabList.splice(k, 1);
          console.log(than.tabList, "than.tabList");
        }
      });
    },
    addTab() {
      if (this.tabList.length >= 5) {
        return this.$message.error("小程序最多五个导航栏！");
      }
      if (this.tabList.length == 0) {
        this.tabList.push({
          text: "名片",
          iconPath: "static/tabs/card.png",
          selectedIconPath: "static/tabs/card_select.png",
          subheading: "名片",
          pagePath: "pages/index/main",
        });
      } else {
        this.tabList.push({
          text: "",
          iconPath: "",
          subheading: "",
          selectedIconPath: "",
        });
      }
    },
    //保存菜单
    saveTab() {
      let content = [];
      for (var k in this.tabList) {
        let v = this.tabList[k];
        if (!v.iconPath || !v.pagePath || !v.selectedIconPath || !v.text) {
          return this.$message.error("导航栏信息不完整，请确认！");
        }
        if (k == 0) {
          v.text = "名片";
        }
        switch (v.pagePath) {
          case "pages/index/main":
            content.push({
              text: v.text,
              subheading: "名片",
              iconPath: "static/tabs/card.png",
              selectedIconPath: "static/tabs/card_select.png",
              pagePath: "pages/index/main",
            });
            break;
          case "pages/media/index":
            content.push({
              text: v.text,
              subheading: "媒体",
              iconPath: "static/tabs/video.png",
              selectedIconPath: "static/tabs/video_select.png",
              pagePath: "pages/media/index",
            });
            break;
          case "pages/explore/main":
            content.push({
              text: v.text,
              subheading: "探索",
              iconPath: "static/tabs/explore.png",
              selectedIconPath: "static/tabs/explore_select.png",
              pagePath: "pages/explore/main",
            });
            break;
          case "pages/Products/main":
            content.push({
              text: v.text,
              subheading: "商城",
              iconPath: "static/tabs/prod_gray.png",
              selectedIconPath: "static/tabs/prod_select.png",
              pagePath: "pages/Products/main",
            });
            break;
          case "pages/Dynamic/main":
            content.push({
              text: v.text,
              subheading: "动态",
              iconPath: "static/tabs/browser.png",
              selectedIconPath: "static/tabs/browser_select.png",
              pagePath: "pages/Dynamic/main",
            });
            break;
          case "pages/WebSite/main":
            content.push({
              text: v.text,
              subheading: "官网",
              iconPath: "static/tabs/computer.png",
              selectedIconPath: "static/tabs/computer_select.png",
              pagePath: "pages/WebSite/main",
            });
            break;
          case "pages/services/main":
            content.push({
              text: v.text,
              subheading: "增值服务",
              iconPath: "static/tabs/member.png",
              selectedIconPath: "static/tabs/member_select.png",
              pagePath: "pages/services/main",
            });
            break;
          default:
            break;
        }
      }
      let data = {
        note: JSON.stringify(this.tabList),
        content: JSON.stringify(content),
      };
      saveTabMenu(data).then((res) => {
        this.$message.success(res.message);
      });
    },
  },
};
</script>

<style lang="scss" scoped>
.appointmentList-view {
  display: flex;
  background: #ffffff;
  padding: 24px;
}
.phoneClass {
  padding: 50px 30px 0px 30px;
  background-image: url("../../../assets/images/iphonex.png");
  width: 400px;
  height: 800px;
  background-repeat: no-repeat;
  background-size: cover;
  background-position: center center;
}
.phoneClass img {
  width: 100%;
}
/deep/.addTabIcon .el-upload--text {
  width: 50px;
  height: 50px;
  line-height: 50px;
  margin-right: 20px;
}
/deep/ .addTabIcon .el-upload-list__item {
  width: 50px;
  height: 50px;
  line-height: 50px;
}
.bradius4 {
  border-radius: 4px;
}
.textc {
  text-align: center;
}
.bced {
  border: 1px solid #ced4da;
}
.ohidden {
  overflow: hidden;
}
.icon-plus {
  position: relative;
  cursor: pointer;
  margin-right: 20px;
}

.icon-plus:before,
.icon-plus:after {
  content: "";
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  background: rgb(206, 212, 218);
}

.icon-plus:before {
  height: 60%;
  width: 1px;
}

.icon-plus:after {
  height: 1px;
  width: 60%;
}
.opacity0 {
  z-index: 1;
  opacity: 0;
}
.position-relative {
  position: relative;
}
.goods-img {
  background-size: cover;
  background-position: center center;
  background-repeat: no-repeat;
}
.avatar {
  width: 100%;
  height: 100%;
}
</style>
